.section {
    margin: 50px 0;
}

.section h1 {
    font-weight: 600;
    font-size: clamp(2rem, 1.6962rem + 1.2464vw, 4.6875rem);
    margin-top: 0;
}

.section h2 {
    font-weight: 600;
    font-size: clamp(1.375rem, 1.0712rem + 1.2464vw, 4.0625rem);
}

.section li {
    font-size: clamp(1.125rem, 1.0049rem + 0.4928vw, 2.1875rem);
    margin: 5px 0;
}

.section p {
    font-size: clamp(1.125rem, 1.0049rem + 0.4928vw, 2.1875rem);
}

.wwd-button {
    margin: 0;
    font-size: clamp(1.125rem, 1.0402rem + 0.3478vw, 1.875rem);
    padding: 7px 20px;
}

.numbers {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    display: none;
}

#ectd-publishing {
    margin-bottom: 100px;
}

.numbers .counter {
    width: 180px;
}

.RBgrad {
    background: linear-gradient(to right, #791233 0%, #0A1D33 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.LBgrad {
    background: linear-gradient(to right, #748EC7 0%, #0A1D33 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.BLgrad {
    background: linear-gradient(to right, #0A1D33 0%, #748EC7 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;   
}

.LRgrad {
    background: linear-gradient(to right, #748EC7 0%, #791233 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.RLgrad {
    background: linear-gradient(to right, #791233 0%, #748EC7 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.BRgrad {
    background: linear-gradient(to right, #0A1D33 0%, #791233 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
