.hero {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2vw;
    min-height: 200px;
}

.counter.cont {
    background: linear-gradient(to right, #748EC7 0%, #0A1D33 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.counter.sub {
    background: linear-gradient(to right, #791233 0%, #0A1D33 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.counter p {
    font-size: 15px;
    margin: 0;
}

.hero-logo {
    margin: 25px 5vw 100px 5vw;
    min-width: 27vw;
}

.custom-shape-divider-top-1666375630 {
    position: absolute;
    top: -2px;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1666375630 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 90px;
}

.custom-shape-divider-top-1666375630 .shape-fill {
    fill: #FAF9F6;
}

.custom-shape-divider-bottom-1666376713 {
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1666376713 svg {
    position: relative;
    display: block;
    width: calc(164% + 1.3px);
    height: 130px;
}

.custom-shape-divider-bottom-1666376713 .shape-fill {
    fill: #FAF9F6;
}

.rev-sec {
    position: relative;
    height: 200px;
    padding-bottom: 50px;
    padding-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rev-top-wave {
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.rev-top-wave svg {
    position: relative;
    display: block;
    width: calc(108% + 1.3px);
    height: 58px;
}

.rev-top-wave .shape-fill {
    fill: #FAF9F6;
}

.welcome-info {
    background-color: #0A1D33;
    padding-top: 150px;
    padding-bottom: 100px;
    color: #FAF9F6;
    position: relative;
}

.welcome-info p {
    font-size: clamp(1.125rem, 0.9696rem + 0.6377vw, 2.5rem);
    margin-bottom: 0;
    text-align: center;
}

.welcome-info button {
    border-color: #0A1D33;
}

.companies {
    position: relative;
    background-color: #FAF9F6;
    display: flex;
    flex-direction: column;
    align-items: center;
}

h1 {
    font-weight: 400;
    font-size: clamp(1.5rem, 1.3163rem + 0.7536vw, 3.125rem);
}

.logos {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
}

.logo {
    width: 20%;
    height: 20%;
}

.logo img {
    width: 100%;
}

.reviewsec {
    background-color: #791233;
    position: relative;
    color: #FAF9F6;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.swiper {
    width: 100vw;
    background-color: #FAF9F6;
}

.reviews h1 {
    text-align: center;
}

.swiper-button-next, .swiper-button-prev {
    color: #791233;
}

.swiper-button-next {
    padding-right: 5vw;
}

.swiper-button-prev {
    padding-left: 5vw;
}

.swiper-wrapper {
    align-items: center;
}

.swiper-pagination-bullet-active {
    background-color: #791233;
}

.swiper-slide {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 20px;
    background-color: #FAF9F6;
    width: 300px;
    color: black;
    height: 100%;
    width: 100%;
    padding: 20px 20vw 20px 20vw;
    box-sizing: border-box;
}

.swiper-slide .rev-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.swiper-slide img {
    border-radius: 50%;
    width: 160px;
}

.swiper-slide h3 {
    margin: 0;
}

.swiper-slide p {
    font-weight: 500;
    font-size: clamp(1rem, 0.9011rem + 0.4058vw, 1.875rem);
}

.swiper-slide h4 {
    font-weight: 400;
    margin: 0;
}

.person {
    display: flex;
    align-items: center;
    justify-content: center;
}

.rev-bot-wave {
    position: absolute;
    top: -2px;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.rev-bot-wave svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 58px;
    transform: rotateY(180deg);
}

.rev-bot-wave .shape-fill {
    fill: #FAF9F6;
}

.custom-shape-divider-top-1666499501 {
    position: absolute;
    top: -2px;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1666499501 svg {
    position: relative;
    display: block;
    width: calc(185% + 1.3px);
    height: 63px;
    transform: rotateY(180deg);
}

.custom-shape-divider-top-1666499501 .shape-fill {
    fill: #FAF9F6;
}


@media screen and (max-width: 1000px) {
    .custom-shape-divider-bottom-1666376713 svg {
        width: calc(264% + 1.3px);
        height: 90px;
    }

    .custom-shape-divider-top-1666375630 svg {
        width: calc(200% + 1.3px);
        height: 80px;
    }

    .custom-shape-divider-top-1666499501 svg {
        width: calc(285% + 1.3px);
        height: 53px;
    }

    .rev-bot-wave svg {
        width: calc(200% + 1.3px);
        height: 58px;
    }

    .rev-top-wave svg {
        width: calc(208% + 1.3px);
        height: 58px;
    }

    .sub, .cont {
        flex: 1 0 100%;
    }

    .counter {
        text-align: center;
    }

    .hero {
        flex-wrap: wrap;
    }

    .hero-logo {
        order: -1;
        margin-left: 20vw;
        margin-right: 20vw;
        margin-bottom: 50px
    }
}

@media screen and (max-width: 720px) {
    .logos {
        flex-direction: column;
    }

    .logo {
        width: 50%;
        margin: 5px;
    }

    .swiper-slide {
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: auto;
    }

    .hero-logo {
        margin-left: 10vw;
        margin-right: 10vw;
    }
}

@media screen and (max-width: 400px) {
    .custom-shape-divider-bottom-1666376713 svg {
        width: calc(364% + 1.3px);
        height: 60px;
    }

    .custom-shape-divider-top-1666375630 svg {
        width: calc(300% + 1.3px);
        height: 50px;
    }

    .custom-shape-divider-top-1666499501 svg {
        width: calc(385% + 1.3px);
        height: 53px;
    }

    .rev-bot-wave svg {
        width: calc(300% + 1.3px);
        height: 58px;
    }

    .rev-top-wave svg {
        width: calc(308% + 1.3px);
        height: 58px;
    }

    .welcome-info {
        padding-top: 50px;
        padding-bottom: 40px;
    }

    .swiper-button-next {
        padding-right: 1vw;
    }
    
    .swiper-button-prev {
        padding-left: 1vw;
    }
}
