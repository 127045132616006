body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FAF9F6;
  overflow-x: hidden;
}

p {
  font-size: clamp(1rem, 0.7598rem + 0.9855vw, 3.125rem);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.content {
  margin: 0 20vw 6vh 20vw;
}

footer {
  width: 100%;
  height: 170px;
  background-color: #0A1D33;
  color: #faf9f6a4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

@media screen and (max-width: 400px) {
  footer {
      flex-direction: column;
      height: auto;
      padding: 20px 0;
  }
}

.footer-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer-info p {
  margin: 0;
  font-size: 13px;
}

.footer-info.right {
  text-align: right;
  margin-right: 5vw;
}
.footer-logo {
  display: flex;
  margin-left: 5vw;
}

button {
  background-color: #791233;
  border: none;
  border-radius: 66px;
  padding: 10px 40px;
  transition: 0.5s;
  color: #FAF9F6;
  margin: auto;
  font-size: clamp(1.25rem, 1.1087rem + 0.5797vw, 2.5rem);
  display: flex;
  align-items: center;
  margin: 40px auto;
}

button:hover {
  background-color: #FAF9F6;
  color: #791233;
  cursor: pointer;
}

button span {
  padding-left: 6px;
}

a {
  text-decoration: none;
  color: inherit;
}

.light-white-grad {
  background-image: linear-gradient(to right, #748EC7 0%, #FAF9F6 100%);
  background-size: 200% auto;
  box-shadow: 0 0 20px #748EC7;
  color: #FAF9F6
}

.light-white-grad:hover {
  background-position: right center;
  color: #0A1D33
}

.red-white-grad {
  background-image: linear-gradient(to right, #791233 0%, #FAF9F6 100%);
  background-size: 200% auto;
  box-shadow: 0 0 20px #791233;
  display: block;
  color: #FAF9F6;
}

.red-white-grad:hover {
  background-position: right center;
  color: #791233;
}

.blue-white-grad {
  background-image: linear-gradient(to right, #0A1D33 0%, #FAF9F6 100%);
  background-size: 200% auto;
  box-shadow: 0 0 20px #0A1D33;
  display: block;
  color: #FAF9F6;
}

.blue-white-grad:hover {
  background-position: right center;
  color: #0A1D33;
}

.counter {
  font-size: clamp(2.5rem, 2.2527rem + 1.0145vw, 4.6875rem);
  width: 330px;
  text-align: center;
}

.footer-waves {
  position: absolute;
  margin-top: -33px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.footer-waves svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 35px;
  transform: rotateY(180deg);
}

.footer-waves .shape-fill {
  fill: #0A1D33;
}

@media screen and (max-width: 1000px) {
  .footer-waves svg {
      width: calc(200% + 1.3px);
  }
}

@media screen and (max-width: 720px) {
  .content {
      margin: 20px 5vw;
  }
}

@media screen and (max-width: 400px) {
  .footer-waves svg {
      width: calc(300% + 1.3px);
  }
}
