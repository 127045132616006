/* body {
    aspect-ratio: 600/900;
    width: 100%;
    height: calc(100vh);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url('svg.png');
    overflow-x: hidden;
} */

.contact-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.contact-content h1 {
    font-size: clamp(6rem, 5.6185rem + 1.5652vw, 9.375rem);
    font-weight: 400;
    margin: 0;
    transition: all 1s ease-out forwards;
}

.contact-content h4 {
    font-size: clamp(1.5rem, 1.281rem + 0.8986vw, 3.4375rem);
    width: 40vw;
    font-weight: 400;
    margin-bottom: 60px;
}

.contact-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 40vw;
}

.contact-form label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 12px;
    font-size: clamp(1rem, 0.9364rem + 0.2609vw, 1.5625rem);
}

.contact-form input {
    height: 40px;
    border: 2px solid #0A1D33;
    font-size: 16px;
    padding-left: 10px;
    border-radius: 5px;
}

.small {
    display: flex;
    width: 100%;
}

.small label {
    flex-grow: 1;
}

.small input, .med input, .large textarea {
    width: 100%;
}

.med, .large {
    width: 100%;
}

.large textarea {
    height: 100px;
    font-family: inherit;
    resize: none;
    padding-top: 10px;
}

.contact-form textarea, .contact-form input {
    border: 2px solid #0A1D33;
    font-size: 16px;
    padding-left: 10px;
    border-radius: 5px;
}

.loading {
    width: 40px;
    height: 100px;
    animation: spin 0.7s infinite linear;
    animation-iteration-count: infinite;
    margin: 10px 0;
}

.loading .top {
    background-color: #0A1D33;
    width: 100%;
    height: 50%;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
}

.loading .bottom {
    background-color: #DDA638;
    width: 100%;
    height: 50%;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 900px) {
    .small {
        flex-direction: column;
    }
}

@media screen and (max-width: 630px) {
    .content h4, .contact-form {
        width: 80vw;
    }
}