nav {
    position: relative;
    display: flex;
    align-items: center;
    padding: 12px 20vw;
    font-size: 20px;
    z-index: 99999;
}

nav ul {
    padding: 0;
    margin: 0;
    display: flex;
}

nav li {
    list-style-type: none;
    margin: 0 1rem;
}

nav li a {
    text-decoration: none;
    display: block;
    color: inherit;
}

.active {
    text-decoration: underline;
}

.hamburger {
    padding: 1.5rem;
    cursor: pointer;
    position: absolute;
    border: none;
    background-color: transparent;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    display: none;
}

/* .hamburger:hover {
    background-color: #2642af;
} */

@media screen and (max-width: 720px) {
    nav {
        padding: 10px 5vw;
    }
}

@media screen and (max-width: 630px) {
    nav {
        padding: 0rem 1.5rem;
    }

    .hamburger {
        display: block;
    }

    nav ul {
        flex-direction: column;
        position: absolute;
        top: 82px;
        left: 0;
        width: 100%;
        height: calc(100vh - 92px);
        background-color: #FAF9F6;
        display: none;
    }

    nav li {
        text-align: center;
        margin: 0;
    }

    nav li a {
        color: black;
        width: 100%;
        padding: 1.5rem 0;
    }

    nav li:hover {
        background-color: #eee;
    }

    nav .expanded ul {
        display: block;
    }
}
  