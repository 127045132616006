.sent {
    height: 80vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pill {
    position: absolute;
}

.spin {
    animation: spin 1s ease-in-out forwards;
}

@keyframes spin {
    100% {transform: rotate(900deg)}
}

.pill .l {
    width: 50px;
    height: 100px;
}

.pill .m {
    width: 30px;
    height: 70px;
}

.pill .s {
    width: 20px;
    height: 60px;
}

.pill .top {
    width: 100%;
    height: 50%;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
}

.pill .bottom {
    width: 100%;
    height: 50%;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
}

@media screen and (max-width: 720px) {
    .pill {
        display: none;
    }

    .contact-content h4 {
        width: 70vw;
    }
}